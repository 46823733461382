<template>
  <master-layout
    smallTitle="i tuoi"
    bigTitle="Ordini"
    titleStyle="horizontal"
    :showNotifications="true"
    :notificationList="notificationList"
    :showProfilePic="true"
  >
    <ListaOrdiniSenzaCalendario v-if="mode"></ListaOrdiniSenzaCalendario>
    <ListaOrdiniConCalendario v-else></ListaOrdiniConCalendario>
    <button
      v-if="mode"
      @click="toggleMode"
      class="fixed bottom center button-bottom-axerta-secondary w-75 text-16"
    >
      Modalità calendario
    </button>
    <button
      v-else
      @click="toggleMode"
      class="fixed bottom center button-bottom-axerta-secondary w-75 text-16"
    >
      Modalità lista
    </button>
  </master-layout>
</template>

<script setup>
import { ref } from "vue"
import ListaOrdiniSenzaCalendario from "./ListaOrdiniSenzaCalendario.vue"
import ListaOrdiniConCalendario from "./ListaOrdiniConCalendario.vue"
import ApiService from "../../common/service.api"

const mode = ref(false)
function toggleMode() {
  mode.value = !mode.value
}

const notificationList = ref([])
const loadingNotificationList = ref(false)

function getNotificationList() {
  loadingNotificationList.value = true
  ApiService.get(`Notification/GetUserNotification`)
    .then((res) => {
      notificationList.value = res.data
    })
    .finally(() => {
      loadingNotificationList.value = false
    })
}

getNotificationList()
</script>
